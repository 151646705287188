import React from 'react'
import { Link } from 'gatsby'

import logo_footer from '../img/logo-footer.svg'
import facebook from '../img/social/facebook.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo_footer}
            alt="Big Thicket Association"
            style={{ width: '24em', height: '5em' }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: '100vw' }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/membership">
                        Join
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/donations">
                        Donate
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/volunteering">
                        Volunteer
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/events">
                        Events
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/bulletins">
                        Bulletins
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/blog">
                        Latest Stories
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/pdf/CC-Privacy-Policy.pdf">
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <a title="facebook BTA" href="https://facebook.com/BigThicketAssociation">
	          <span className="icon">
                    <img src={facebook} alt="Facebook BTA" />
                  </span>
                </a>
                BTA
                <a title="facebook T.O.D." href="https://facebook.com/TODATBI">
	          <span className="icon">
                    <img src={facebook} alt="Facebook T.O.D." />
                  </span>
                </a>
                T.O.D.
                <a title="facebook N.R.Adventures" href="http://facebook.com/pages/Neches-River-Adventures/209311866070">
	          <span className="icon">
                    <img src={facebook} alt="Facebook N.R.Adventures" />
                  </span>
                </a>
                N.R.Adventures
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
